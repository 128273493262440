import { ArrowForwardIcon, ChevronDownIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Center,
    Grid,
    GridItem,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { FaCheck, FaMinus } from "react-icons/fa6";
import { motionPropsZeroAnimation, toTitleCase } from '../../helpers'
import { useEffect, useState } from 'react'
import { useGetProductsForPricingTable, useGetShortPlanId, useIsDesktop } from "../../../shared/hooks"

import { ChangePlanButton } from './ChangePlan'
import { InView } from "react-intersection-observer";
import { LabelPopover } from '../../../marketing/src/-sections/Pricing/LabelPopover'
import { isInMarketing } from '../../../shared/helpers'
import { plansTableRows } from '../../../shared/_plans'
import { useCanStartNewTrial } from '../../../shared/hooks/pricing'
import { useInOrganization } from '../../hooks'

export const PricingFeaturesTable = () => {
    const products = useGetProductsForPricingTable()
    const [selectedPlan, setSelectedPlan] = useState(null)
    return (
        <InView triggerOnce>
            {({ inView, ref }) => {
                return (
                    <Box ref={ref} opacity={inView ? 1 : 0} transition="opacity 250ms">
                        <Stack spacing={6} visibility={inView ? "visible" : "hidden"}>
                            <ProductFeaturesTable products={products} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
                            <ProductCallToActionTable products={products} selectedPlan={selectedPlan} />
                        </Stack>

                    </Box>
                )
            }}
        </InView>
    )
}

export const ProductFeaturesTable = ({ selectedPlan, setSelectedPlan, showAnnualBilling }) => {
    const products = useGetProductsForPricingTable()
    const isDesktop = useIsDesktop()
    const rowIndex = products?.findIndex(p => p?.id === selectedPlan)
    useEffect(() => {
        if (isDesktop && selectedPlan) setSelectedPlan(null)
        if (!isDesktop && !selectedPlan) setSelectedPlan('free')
    }, [isDesktop])
    return (
        <Stack spacing={10}>
            {plansTableRows(showAnnualBilling).map((feature, featureId) => {
                return (
                    <Table
                        key={featureId}
                        display="grid"
                    >
                        <Thead pos="sticky" top="0px">
                            <Grid
                                as={Tr}
                                w="full"
                                templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
                                alignItems="center"
                            >
                                <GridItem
                                    as={Th}
                                    pl={0}
                                    fontSize={{ base: "sm", md: "md" }}
                                    fontWeight="bold"
                                    color="fg.default"
                                    bgColor="rarchy-bg-white"
                                    borderTop="none"
                                    borderBottomColor="rarchy-border-sitemap-editor"
                                    textTransform="initial"
                                    letterSpacing="normal"
                                >
                                    {feature?.group}
                                </GridItem>
                                {products?.map((p, i) => {
                                    // if (!isDesktop && p?.id !== selectedPlan) return null;
                                    return (
                                        <GridItem
                                            as={Th}
                                            key={p?.id}
                                            display={{ base: p?.id !== selectedPlan ? 'none' : 'initial', md: 'initial' }}
                                            w="full"
                                            p={{ base: 0, md: 'initial' }}
                                            py={{ base: 1, md: 3 }}
                                            fontSize={{ base: "sm", md: "md" }}
                                            fontWeight="bold"
                                            color="fg.default"
                                            bgColor="rarchy-bg-white"
                                            borderTop="none"
                                            borderBottomColor="rarchy-border-sitemap-editor"
                                            textTransform="initial"
                                            letterSpacing="normal"
                                        >
                                            <Center display={{ base: "none", md: "flex" }}>{p?.name}</Center>
                                            <Menu placement="bottom" autoSelect={false}>
                                                <MenuButton
                                                    as={Button}
                                                    display={{ base: "flex", md: "none" }}
                                                    colorScheme="gray"
                                                    fontSize="sm"
                                                    rightIcon={<ChevronDownIcon pos="absolute" top="5px" right="12px" fontSize="24px !important" />}
                                                    w="full"
                                                    size="xs"
                                                    fontWeight="semibold !important"
                                                    variant="outline"
                                                    h="36px"
                                                >
                                                    {p?.name}
                                                </MenuButton>
                                                <MenuList motionProps={motionPropsZeroAnimation}>
                                                    {products?.map(p => {
                                                        return (
                                                            <MenuItem
                                                                key={p?.id}
                                                                justifyContent="center"
                                                                fontWeight="semibold !important"
                                                                onClick={() => setSelectedPlan(p?.id)}
                                                            >
                                                                {p?.name}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </MenuList>
                                            </Menu>
                                        </GridItem>
                                    )
                                }
                                )}
                            </Grid>
                        </Thead>
                        <Tbody>
                            {feature?.rows?.map((row, id) => {
                                return (
                                    <Grid
                                        as={Tr}
                                        key={id}
                                        w="full"
                                        templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
                                    >
                                        <GridItem as={Th} bgColor="rarchy-bg-white" border="none" borderBottom="1px solid" borderBottomColor="rarchy-border-sitemap-editor" pl={row?.subheader ? 2 : row?.indent ? 3 : 0} >
                                            <LabelPopover feature={row} opts={{ fontSize: { base: "sm", md: "15px" }, fontWeight: row?.subheader ? "semibold" : "normal" }} />
                                        </GridItem>
                                        {row?.data?.map((cell, i) => {
                                            // if (!isDesktop && rowIndex !== i) return null
                                            return (
                                                <GridItem
                                                    as={Td}
                                                    key={i}
                                                    display={{ base: rowIndex !== i ? 'none' : 'initial', md: 'initial' }}
                                                    color="fg.default"
                                                    bgColor={!cell ? "rarchy-bg-white" : "rarchy-bg-subtle"}
                                                    borderBottomColor="rarchy-border-sitemap-editor"
                                                >
                                                    <Center
                                                        fontSize={{ base: "sm", md: "md" }}
                                                        lineHeight={4}
                                                    >
                                                        {renderCell(cell)}
                                                    </Center>
                                                </GridItem>
                                            )
                                        })}
                                    </Grid>
                                )
                            })}
                        </Tbody>
                    </Table>
                )
            })}
        </Stack>
    )
}

const renderCell = (cell) => {
    if (cell === true || cell?.asterix) return <><Icon as={FaCheck} fontSize="lg" />{cell?.asterix && "*"}</>
    if (cell === false) return <Icon as={FaMinus} fontSize="lg" />
    return cell?.toLocaleString()
}

const ProductCallToActionTable = (props) => {
    const { showAnnualBilling, selectedPlan } = props
    const products = useGetProductsForPricingTable()
    const isDesktop = useIsDesktop()
    return (
        <Table sx={{ tableLayout: 'fixed' }}>
            <Tbody>
                <Tr>
                    {products?.length > 1 && <Td borderBottomWidth="0px" />}
                    {products?.map((p, index) => {
                        if (!isDesktop && p?.id !== selectedPlan) return null;
                        return (
                            <Td key={index} borderBottomWidth="0px"
                            >
                                <Stack spacing="3" mb={20}>
                                    <ActionButton product={p} showAnnualBilling={showAnnualBilling} />
                                </Stack>
                            </Td>
                        )
                    })}
                </Tr>
            </Tbody>
        </Table>
    )
}

const ActionButton = ({ product, showAnnualBilling }) => {

    const showUpgrade = useInOrganization()
    const planId = useGetShortPlanId()
    const canStartNewTrial = useCanStartNewTrial()

    const isUserPlan = planId === product.id
    const isFreePlan = product.id === 'free'
    const isDowngrade = isPlanADowngrade(planId, product?.id)

    if (!isInMarketing && showUpgrade && isFreePlan) return null

    if (showUpgrade) return (
        <ChangePlanButton
            isDisabled={isUserPlan}
            text={`${isUserPlan ? 'Your current plan' : (isDowngrade ? 'Downgrade' : 'Upgrade')}`}
            colorScheme={(isUserPlan || isDowngrade) ? null : "blue"}
            planId={`${product.stripeId}${showAnnualBilling ? 0 : ''}`}
            return_url={window.location.href}
        />
    )

    return (
        <Button
            size="lg"
            isDisabled={isInMarketing ? false : isUserPlan}
            w="full"
            colorScheme={isUserPlan ? 'gray' : "blue"}
            fontSize="md"
            rightIcon={isInMarketing ? <ArrowForwardIcon /> : null}
            iconSpacing={4}
            onClick={async () => {
                if (!process.env.isNext) { // HAS TO BE THIS, not isInMarketing
                    const {store} = await import('../../store')
                    const { toggleUpgradeModal } = await import('../../store/actions/ui-actions')
                    store.dispatch(toggleUpgradeModal({ showing: true, planId: product.id, screen: 'plans-pricing' }))
                } else {
                    const router = await import('next/router')
                    router.push(`/app/${isFreePlan ? 'signup' : `upgrade-modal?planId=${toTitleCase(product.id)}`}`)
                }
            }}
        >
            {isInMarketing ? 'Get started' : isUserPlan ? 'Your current plan' : canStartNewTrial ? `Try ${toTitleCase(product?.name)} for free` : `Upgrade`}
        </Button>
    )

}

const isPlanADowngrade = (currentPlanId, newPlanId) => {
    if (currentPlanId === 'agency' || currentPlanId === 'enterprise') return true;
    if (currentPlanId === 'team') return newPlanId === 'pro' ? true : false;
}