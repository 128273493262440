'use client'

import { Box, Button, HStack, Image, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react"

import { UpDownIcon } from "@chakra-ui/icons"
import { motionPropsZeroAnimation } from "../../../helpers"
import { usePricingContext } from "../../../../shared/hooks"

const PricingCurrency = () => {
    const { currencies, selectedCurrency, setSelectedCurrency } = usePricingContext()
    return (
        <Menu autoSelect isLazy matchWidth>
            <MenuButton
                as={Button}
                size="xl"
                px={4}
                py={2}
                borderRadius='md'
                borderWidth='1px'
                bg="transparent"
                color="fg.default"
                w={{ base: "92.5%", sm: "auto" }}
                rightIcon={<UpDownIcon ml={4} color="fg.subtle" fontSize="16px !important" />}
            >
                <Box ml={-2}>{renderCurrency(currencies, selectedCurrency)}</Box>
            </MenuButton>
            <MenuList minW="fit-content" px={2} motionProps={motionPropsZeroAnimation}>
                {Object.keys(currencies)?.map(currency => {
                    return (
                        <MenuItem
                            key={currency}
                            borderRadius="md"
                            px={2}
                            onClick={() => setSelectedCurrency(currency?.toLowerCase())}
                        >
                            {renderCurrency(currencies, currency)}
                        </MenuItem>
                    )
                })}
            </MenuList>
        </Menu>
    )
}

const renderCurrency = (currencies, currencyCode) => {
    const currency = currencies?.[currencyCode || 'usd']
    return (
        <HStack fontWeight="medium" color="fg.default">
            <Box border="1px solid" borderColor="rarchy-border-sitemap-editor" borderRadius="sm" p={1.5}>
                <Image
                    h="15px"
                    w="20px"
                    alt={`${currency?.ISO} flag`}
                    src={`https://help.rarchy.com/hubfs/Website/Flags/${currency?.ISO || 'US'}.svg?ver=${new Date().getTime()}`}
                    rel="preload"
                    priority="true"
                    fetchpriority="high"
                    suppressHydrationWarning
                />
            </Box>
            <Text suppressHydrationWarning>
                {currency?.code}
            </Text>
            <Text suppressHydrationWarning>
                {currency?.symbol}
            </Text>
        </HStack>
    )
}

export default PricingCurrency