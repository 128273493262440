import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { useIsDesktop, usePricingContext } from "../../../../shared/hooks"
import { UpDownIcon } from "@chakra-ui/icons"
import { motionPropsZeroAnimation } from "../../../helpers"

const billing_intervals = [{ key: 'month', label: 'Pay monthly' }, { key: 'year', label: 'Pay yearly (save 15%)' }]

const PricingBillingInterval = () => {
    const { billingInterval, setBillingInterval } = usePricingContext()
    const isDesktop = useIsDesktop()
    const selected = billing_intervals?.find(b => b?.key === billingInterval)?.label
    return (
        <>
            <RadioGroup
                display={{ base: "none", md: "block" }}
                value={billingInterval}
                size="lg"
                fontWeight="medium"
                color="gray.600"
                onChange={setBillingInterval}
                colorScheme="blue"
            >
                <Stack direction='row' spacing={6}>
                    {billing_intervals?.map((interval) => {
                        return (
                            <Radio key={interval?.key} value={interval?.key}>
                                <Text color={billingInterval === interval?.key ? "blue.600" : "unset"}>
                                    {interval?.label}
                                </Text>
                            </Radio>
                        )
                    })}
                </Stack>
            </RadioGroup>
            <Menu autoSelect matchWidth placement="bottom">
                <MenuButton
                    as={Button}
                    display={{ base: "flex", md: "none" }}
                    w={{ base: "92.5%", sm: "55%" }}
                    size="xl"
                    px={4}
                    pl={5}
                    py={2}
                    borderRadius='md'
                    borderWidth='1px'
                    bg="transparent"
                    color="fg.default"
                    fontWeight="medium"
                    rightIcon={<UpDownIcon ml={4} color="fg.subtle" fontSize="16px !important" />}
                >
                    <Box ml={-2} textAlign="start">{selected}</Box>
                </MenuButton>
                <MenuList px={2} motionProps={motionPropsZeroAnimation}>
                    {billing_intervals?.map(interval => {
                        return <MenuItem key={interval?.key} color="fg.default" onClick={() => setBillingInterval(interval?.key)} borderRadius="md" px={2} fontWeight="medium">{interval?.label}</MenuItem>
                    })}
                </MenuList>
            </Menu>
        </>
    )
}

export default PricingBillingInterval