import React, { useState } from 'react'
import {
    Button,
} from "@chakra-ui/react"
import { useFirebase } from 'react-redux-firebase';
import { useGetSubscription } from '../../hooks';
import { useIsBilledYearly } from '../../../shared/hooks/pricing';

export const ChangePlanButton = ({ isDisabled, text, colorScheme, variant, planId, return_url, rightIcon, interval }) => {
    const firebase = useFirebase()
    const [loading, setLoading] = useState(false)
    const subscription = useGetSubscription()
    const { customer_id, id: subscription_id } = subscription;
    // if not setting interval and user is billed yearly, ensure that user stays annual (so we can always send through the monthly planId to this component, rather than having to check users interval each place this button is invoked)
    const isBilledYearly = useIsBilledYearly();
    if (!interval && isBilledYearly) planId = `${planId}0`;
    return (
        <Button
            w="full"
            isDisabled={isDisabled}
            variant={variant || "solid"}
            size="lg"
            fontSize="md"
            rightIcon={rightIcon || null}
            colorScheme={colorScheme}
            isLoading={loading}
            onClick={async () => {
                setLoading(true)
                try {
                    const sessionURL = await firebase.functions().httpsCallable('stripe-createCustomerPortalSession')({ return_url, customerId: customer_id, flow_data: { type: "subscription_update_confirm", subscription_update_confirm: { subscription: subscription_id, items: [{ id: subscription?.plan?.subscription_id, price: planId, quantity: 1 }] } } })
                    if (sessionURL?.data) window.location = sessionURL.data
                } catch (e) {
                    console.error(e)
                } finally {
                    setTimeout(() => setLoading(false), 2000);
                }
            }}>
            {text}
        </Button>
    )
}