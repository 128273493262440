import { Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, chakra } from "@chakra-ui/react"

import { motionPropsZeroAnimation } from "../../../../src/helpers/"

export const LabelPopover = ({ feature, opts = {} }) => {
    const { fontSize, fontWeight } = opts
    const { subheader } = feature
    return (
        <Popover trigger={subheader ? "none" : "hover"} placement="bottom" matchWidth openDelay={175} closeDelay={0}>
            <PopoverTrigger>
                <chakra.span
                    borderBottom={`${subheader ? 0 : 2}px dotted`}
                    borderColor="rarchy-border-400"
                    fontSize={fontSize || "sm"}
                    fontWeight={fontWeight || "normal"}
                    display='inline-block'
                    color="fg.default"
                    textTransform="initial"
                    letterSpacing="normal"
                    _hover={{ cursor: subheader ? 'initial' : 'pointer' }}
                >
                    {feature?.label}
                </chakra.span>
            </PopoverTrigger>
            <Portal>
                <PopoverContent rounded="sm" motionProps={motionPropsZeroAnimation} maxW="200px">
                    <PopoverBody color="fg.default" fontSize="sm" fontWeight="normal">{feature?.description}</PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    )
}