import { BiSolidMinusSquare, BiSolidPlusSquare } from "react-icons/bi";
import { Box, Button, Center, Icon, LightMode, SimpleGrid, useBoolean } from '@chakra-ui/react'

import { Container } from '../../../-components/Container'
import { InView } from 'react-intersection-observer'
import { PricingCard } from '../PricingCard'
import { PricingFeaturesTable } from '../../../../../src/screens/Pricing/PricingFeaturesTable'
import { PricingHeader } from '../../../../../src/screens/Pricing/Components/Header'
import data from './_data'
import { useGetProductsForPricingTable } from '../../../../../shared/hooks'

export const PricingCards = ({ alwaysDisplayTable }) => {
    const [shouldShowTable, setShouldShowTable] = useBoolean(alwaysDisplayTable || false)
    const products = useGetProductsForPricingTable()
    return (
        <LightMode>
            <InView triggerOnce>
                {({ inView, ref, entry }) => (
                    <Box as='section' id='pricing' ref={ref}>
                        <Box /* visibility={!inView ? "hidden" : "block"} */>
                            <PricingHeader alwaysDisplayTable={alwaysDisplayTable} />
                            <Container>
                                <SimpleGrid
                                    alignItems='flex-start'
                                    columns={{ base: 1, md: 2, lg: 3 }}
                                    spacing={{ base: '12', lg: '8' }}
                                >
                                    {products?.map(product => {
                                        console.log(data(product)?.[product?.id])
                                        return (
                                            <PricingCard
                                                key={product?.id}
                                                product={product}
                                                label={product?.description}
                                                data={data(product)?.[product?.id]}
                                            />
                                        )
                                    })}
                                </SimpleGrid>
                                <Center visibility={!alwaysDisplayTable ? "visible" : "hidden"}>
                                    <Button size="lg" my={!alwaysDisplayTable ? 12 : 6} borderColor="rarchy-border-400" variant="outline" leftIcon={shouldShowTable ? <Icon as={BiSolidMinusSquare} fontSize="2xl !important" /> : <Icon as={BiSolidPlusSquare} fontSize="2xl !important" />} onClick={setShouldShowTable.toggle}>
                                        {`See ${shouldShowTable ? 'fewer' : 'all'} features`}
                                    </Button>
                                </Center>
                                {shouldShowTable && <PricingFeaturesTable showUpgrade={false} />}
                            </Container>
                        </Box>
                    </Box>
                )}
            </InView>
        </LightMode >
    )
}

/* when alwaysDisplayTable is true, we want to hide the "See all features button" and always display the table */