import { Box, Stack } from "@chakra-ui/react"

const data = (product) => {
    return {
        free: [
            {
                label: "Basic sitemap editor",
                description: "Edit your sitemap pages with some features limited."
            },
            {
                label: `${product?.pricing?.limits?.pages_per_import} pages per sitemap`,
                description: `Import as many times as you need, up to ${product?.pricing?.limits?.pages_per_import?.toLocaleString()} pages per import.`
            },
            {
                label: `${product?.pricing?.limits?.screenshots?.toLocaleString()} screenshots per month`,
                description: `Capture up to ${product?.pricing?.limits?.screenshots?.toLocaleString()} page thumbnails every month.`
            },
            {
                label: "Website crawler",
                description: "Generate your sitemap from a fresh crawl of your website."
            },
            {
                label: 'Share with view-only access',
                description: "Anyone with a project link can view, no need for sign-in."
            },
            {
                label: 'Unlimited exports',
                description: "Export your projects in all formats, as many times as you need."
            },
            {
                label: 'Basic support',
                description: "Raise a ticket, and we'll get back to you within 3 business days."
            }
        ],
        team: [
            {
                label: "Full sitemap editor",
                description: "Full access to our real-time, collaborative, drag and drop editor."
            },
            {
                label: `${product?.pricing?.limits?.pages_per_import?.toLocaleString()} pages per sitemap`,
                description: `Import as many times as you need, up to ${product?.pricing?.limits?.pages_per_import?.toLocaleString() } pages per import.`
            },
            {
                label: `${product?.pricing?.limits?.screenshots?.toLocaleString()} screenshots per month`,
                description: `Capture up to ${product?.pricing?.limits?.screenshots?.toLocaleString()} full-page screenshots every month.`
            },
            {
                label: `Unlimited projects`,
                description: "No limit on the number of active projects in your workspace."
            },
            {
                label: 'Role-based access control',
                description: "Keep control of your account by assigning admin/user roles to your team members."
            },
            {
                label: `Shared workspace`,
                description: "Stay aligned and avoid duplicating work by bringing everyone under one Rarchy account."
            },
            {
                label: `Folder permissions`,
                description: "Keep your projects organized with public/private folders."
            },
            {
                label: "Share with edit/comment access",
                description: "Invite unlimited collaborators to comment on/edit your projects."
            },
            {
                label: "Unlimited version history",
                description: "View a full timeline of all changes made to your projects, by all users."
            },
            {
                label: 'Premium support',
                description: "Raise a ticket, and we'll get back to you within 2 business days."
            },
        ],
        agency: [
            {
                label: `Unlimited seats`,
                description: (
                    <Stack>
                        <Box>
                        {`Add unlimited users to your account for free.`}
                        </Box>
                    </Stack>
                )
            },
            {
                label: `${product?.pricing?.limits?.pages_per_import?.toLocaleString()} pages per sitemap`,
                description: `Import as many times as you need, up to ${product?.pricing?.limits?.pages_per_import?.toLocaleString()} pages per import.`
            },
            {
                label: `${product?.pricing?.limits?.screenshots?.toLocaleString()} screenshots per month`,
                description: `Capture up to ${product?.pricing?.limits?.screenshots?.toLocaleString()} full-page screenshots every month.`
            },
            {
                label: 'Priority technical support',
                description: "Get a designated technical support rep to get your issues addressed sooner"
            },
        ]
    }
}

export default data